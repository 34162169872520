import React from 'react'
import translations from './translations'

translations.add('en', {
  payByLink: 'Payment links (issued by default)',
  virtualTerminal: 'Virtual Terminal',
  websitePayments: 'Website payments',
  zashDigital: 'Zash Digital Ordering',
  amex: 'AmEx [MID]',
  unionPay: 'Union Pay',
  discovery: 'Discovery',
  openBanking: 'Open Banking',
  payByBank: 'Pay By Bank',
  klarna: 'Klarna',
  options: 'Options:',
  storeWebSite: 'Outlet website',
  webSiteRequired: 'Store website required',
  aliPay: 'AliPay (Coming soon)',
  configureEcom: 'Configure Ecommerce product',
  paymentMethods: 'Payment methods',
  cards: 'Cards (Visa, Mastercard, UnionPay, Diners & Discover)',
  productConfiguration: 'Product configuration',
  minTerm: 'Minimum term',
  minTermRequired: 'Minimum term required',
  websiteInvalid: 'Website invalid',
  invalidWebsiteDomain:
    'Domains: dnapayments.com;dnapaymentsgroup.com;optomany.com;123send.com forbidden, use another domain',
  ecomWebsitePlaceholder: 'https://www.example.com',
  websitePopover: (
    <>
      <p>
        The Merchant website URL is mandatory for all e-commerce MIDs, including those using Pay by Link only. It must
        be a valid and functional URL with the merchant's contact details clearly displayed.
      </p>
      <p>
        If the merchant does not have a dedicated website, they must still confirm their online presence. In such cases,
        a link to their social media profile, such as Facebook, LinkedIn, or other relevant platforms, should be
        provided.
      </p>
    </>
  )
})
