export enum PaymentStatuses {
    all = 'all',
    new = 'new',
    waitingApproval = 'waiting_approval',
    processing = 'processing',
    settled = 'settled',
    failed = 'failed',
    rejected = 'rejected',
    bounced = 'bounced'
}
