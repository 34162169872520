import { message, FormInstance } from 'antd'
import { inject, injectable } from 'inversify'
import { makeObservable, action, observable, computed, runInAction } from 'mobx'
import _ from 'lodash'
import { log } from 'dna-common'
import { clearMobx } from '~/code/services'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { AppStore } from '~/code/stores/AppStore'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { IChangeSettlementTypeStore } from '~/code/pages/MerchantsManagement/components/ChangeSettlementStatus/IChangeSettlementTypeStore'
import {
  SettlementPayment,
  ChargeMethod,
  grossByUberIdAvailableLength
} from '~/code/pages/MerchantsManagement/components/ChangeSettlementStatus/constants'
import { SettlementFormValuesType } from '~/code/pages/MerchantsManagement/components/ChangeSettlementStatus/models'
import { ChangeGrossSettlementDossierV2ReqType, DictionaryValueType } from './models/merchant-dossier-v2'
import { startProcessChangeSettlementDossierV2, getMerchatAttributes, getDictionary } from './services/fetchers'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import {
  stringType,
  dailyNet,
  directDebit,
  merchantAttributeCodes,
  booleanType,
  dictionariesEnum,
  monthlyNet
} from './constants'
import translations from './translations'
@injectable()
export class ChangeSettlementTypeStore implements IChangeSettlementTypeStore {
  storesDossierV2Store: IStoresDossierV2Store
  merchantsManagementStore: MerchantsManagementStore
  isChangeSettlementLoading: boolean = false
  monthlyNet: string = monthlyNet
  dailyNet: string = dailyNet
  directDebit: string = directDebit
  settlementPaymentValue: string = null
  settlementTypeValue: string = null
  chargeMerchantValue: string = null
  isSettlementDataLoading: boolean = true
  settlementTypeDictionary: DictionaryValueType[] = null
  constructor(
    @inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store,
    @inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore
  ) {
    this.storesDossierV2Store = storesDossierV2Store
    this.merchantsManagementStore = merchantsManagementStore
    makeObservable(this, {
      isChangeSettlementLoading: observable,
      settlementPaymentValue: observable,
      settlementTypeValue: observable,
      chargeMerchantValue: observable,
      isSettlementDataLoading: observable,
      settlementTypeDictionary: observable,
      settlementPayment: computed,
      settlementFormValues: computed,
      isGrossByUberIdAvailable: computed,
      isGrossAvailable: computed,
      settlementTypeDisplayValue: computed,

      changeSettlement: action,
      handleSubmitSettlement: action,
      reset: action,
      loadMerchantAttributes: action,
      init: action
    })
  }

  reset() {
    this.isChangeSettlementLoading = false
    this.settlementPaymentValue = null
    this.settlementTypeValue = null
    this.chargeMerchantValue = null
  }

  get isGrossByUberIdAvailable(): boolean {
    const bankDetails = this.merchantsManagementStore.companyFullDossierV2?.bankDetail || []
    return bankDetails.length <= grossByUberIdAvailableLength
  }

  get isGrossAvailable(): boolean {
    const bankDetails = this.merchantsManagementStore.companyFullDossierV2?.bankDetail ?? []
    return bankDetails.some(item => item.linkedContracts?.some(contract => contract.isForDirectDebit))
  }

  get settlementTypeDisplayValue(): string {
    if (!this.settlementTypeDictionary?.length) {
      return ''
    }
    const dictionaryItem = this.settlementTypeDictionary.find(item => item.shortCode === this.settlementTypeValue)

    return dictionaryItem?.displayValue || ''
  }

  async init() {
    runInAction(() => {
      this.isSettlementDataLoading = true
    })
    this.reset()
    await this.loadMerchantAttributes()
    await this.loadSettlementsDictionary()
    runInAction(() => {
      this.isSettlementDataLoading = false
    })
  }

  get settlementPayment(): { perCompanyUberId: boolean; perOutletMid: boolean } {
    const defaultPayment = {
      perCompanyUberId: false,
      perOutletMid: false
    }
    switch (this.settlementPaymentValue) {
      case SettlementPayment.mid:
        return { ...defaultPayment, perOutletMid: true }
      case SettlementPayment.uberid:
        return { ...defaultPayment, perCompanyUberId: true }
      default:
        return defaultPayment
    }
  }

  get settlementTypeValues() {
    const defaultSettlements = {
      dailyNetSettlements: false,
      grossSettlementsByMid: false,
      grossSettlementsByUberId: false,
      monthlyNetSettlements: false
    }

    switch (this.settlementTypeValue) {
      case dailyNet:
        return { ...defaultSettlements, dailyNetSettlements: true }
      case monthlyNet:
        return { ...defaultSettlements, monthlyNetSettlements: true }
      case directDebit:
        if (this.chargeMerchantValue === ChargeMethod.mid) return { ...defaultSettlements, grossSettlementsByMid: true }
        if (this.chargeMerchantValue === ChargeMethod.uberid)
          return { ...defaultSettlements, grossSettlementsByUberId: true }
        return defaultSettlements
      default:
        return defaultSettlements
    }
  }

  get settlementFormValues(): SettlementFormValuesType {
    return { ...this.settlementPayment, ...this.settlementTypeValues }
  }

  handleSubmitSettlement = (form: FormInstance) => this.changeSettlement(form)

  async loadMerchantAttributes() {
    try {
      const { dsrId } = this.merchantsManagementStore?.companyFullDossierV2?.mainInfo || {}
      if (!dsrId) return
      const { status, error, result } = await getMerchatAttributes(dsrId)
      if (status === 200 && !error) {
        this.settlementPaymentValue =
          result?.filter(item => item?.paramId === merchantAttributeCodes.settlementPayment)?.[0]?.paramValue || null
        this.chargeMerchantValue =
          result?.filter(item => item?.paramId === merchantAttributeCodes.chargeMerchant)?.[0]?.paramValue || null
        this.settlementTypeValue =
          result?.filter(item => item?.paramId === merchantAttributeCodes.settlementType)?.[0]?.paramValue || null
      }
    } catch (error) {
      this.settlementPaymentValue = null
      message.error(error.message)
    }
  }

  async loadSettlementsDictionary() {
    try {
      const { status, result, error } = await getDictionary(dictionariesEnum.settlementType)

      if (status !== 200 || error) {
        message.error(error.message || translations().errLoadingSettlementReason)
      } else {
        this.settlementTypeDictionary = result
      }
    } catch (error) {
      log(error)
      message.error(error.message)
    }
  }

  handleSettlementsFormChange(changedValues: any, formSettlements: FormInstance) {
    const updateFormFields = fields => {
      formSettlements.setFieldsValue(fields)
    }

    if (changedValues.dailyNetSettlements) {
      updateFormFields({ settlementImmediate: false })
    }

    const exclusiveFields = [
      'dailyNetSettlements',
      'grossSettlementsByMid',
      'grossSettlementsByUberId',
      'monthlyNetSettlements'
    ]

    exclusiveFields.forEach(field => {
      if (changedValues[field]) {
        const resetFields = exclusiveFields
          .filter(f => f !== field)
          .reduce((acc, f) => {
            acc[f] = false
            return acc
          }, {})
        updateFormFields(resetFields)
      }
    })
  }

  createChangeSettlementData(form: FormInstance): ChangeGrossSettlementDossierV2ReqType {
    const { dsrId, companyName, companyNumber } = this.storesDossierV2Store?.companyFullDossierV2?.mainInfo || {}
    const formValues = form.getFieldsValue()

    let settlementType = null
    if (formValues?.grossSettlementsByUberId || formValues?.grossSettlementsByMid) settlementType = this.directDebit
    if (formValues?.monthlyNetSettlements) settlementType = this.monthlyNet
    if (formValues?.dailyNetSettlements) settlementType = this.dailyNet
    let chargeMethod = null
    if (formValues?.grossSettlementsByUberId) {
      chargeMethod = ChargeMethod.uberid
    } else if (formValues?.grossSettlementsByMid) {
      chargeMethod = ChargeMethod.mid
    }

    let settlementPayment = null
    if (formValues?.perCompanyUberId) settlementPayment = SettlementPayment.uberid
    if (formValues?.perOutletMid) settlementPayment = SettlementPayment.mid

    return {
      variables: {
        dossierId: {
          value: dsrId?.toString(),
          type: stringType
        },
        companyName: {
          value: companyName,
          type: stringType
        },
        companyNumber: {
          value: companyNumber,
          type: stringType
        },
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        settlementType: {
          value: settlementType,
          type: stringType
        },
        chargeMerchant: {
          value: chargeMethod,
          type: stringType
        },
        settlementImmediate: {
          value: formValues?.settlementImmediate,
          type: booleanType
        },
        settlementPayment: {
          value: settlementPayment,
          type: stringType
        }
      }
    }
  }

  async changeSettlement(form: FormInstance) {
    try {
      runInAction(() => {
        this.isChangeSettlementLoading = true
      })

      const initialValues = this.settlementFormValues
      const finalValues = clearMobx(form.getFieldsValue())
      const initialValuesToCompare = _.omit(initialValues, 'settlementImmediate')
      const finalValuesToCompare = _.omit(finalValues, 'settlementImmediate')

      if (_.isEqual(initialValuesToCompare, finalValuesToCompare)) {
        message.error(translations().errorNoChangesSettlementType)
        return
      }

      if (
        !finalValues.dailyNetSettlements &&
        !finalValues.grossSettlementsByMid &&
        !finalValues.grossSettlementsByUberId
      ) {
        message.error(translations().errorNoSettlementType)
        return
      }

      const startProcessChangeSettlementReq = this.createChangeSettlementData(form)
      const { status, error, result } = await startProcessChangeSettlementDossierV2(startProcessChangeSettlementReq)

      if (status !== 200 || error) {
        message.error(error.message)
      } else {
        message.success(result.message || translations().applicationSuccessful)
        goToRoute(Routes.BPM_CHANGE_OF_SETTLEMENT_KIND)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => {
        this.isChangeSettlementLoading = false
      })
    }
  }
}
