import React from 'react'
import { Badge } from 'antd'
import { ColumnType } from 'antd/lib/table'
import translations from '../../../translations'
import { generateBadgeStatus } from '../services/ui-helpers'


export const PaymentsTableColumns: ColumnType<any>[] = [
    {
        title: translations().tableColumns.postingDate,
        dataIndex: 'postingDate',
        key: 'postingDate',
        width: 130,
        sorter: true
    },
    {
        title: translations().tableColumns.merchant,
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: 283
    },
    {
        title: translations().tableColumns.amountToMerchant,
        dataIndex: 'amountToMerchant',
        key: 'amountToMerchant',
        width: 175,
        sorter: true
    },
    {
        title: translations().tableColumns.currency,
        dataIndex: 'currency',
        key: 'currency',
        width: 81
    },
    {
        title: translations().tableColumns.status,
        dataIndex: 'status',
        key: 'status',
        width: 130,
        render: (value) => {
            return <Badge status={generateBadgeStatus(value)} text={translations().paymentStatuses[value]} />
        }
    },
    {
        title: translations().tableColumns.sortCode,
        dataIndex: 'sortCode',
        key: 'sortCode',
        width: 97
    },
    {
        title: translations().tableColumns.accountNumber,
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        width: 140
    },
    {
        title: translations().tableColumns.endToEndId,
        dataIndex: 'endToEndId',
        key: 'endToEndId',
        width: 253
    },
    {
        title: translations().tableColumns.payOutReference,
        dataIndex: 'details',
        key: 'details',
        width: 253
    },
    {
        title: translations().tableColumns.bankPaymentId,
        dataIndex: 'bankPaymentId',
        key: 'bankPaymentId',
        width: 343
    },
    {
        title: translations().tableColumns.documentId,
        dataIndex: 'documentId',
        key: 'documentId',
        width: 130,
        sorter: true
    },
    {
        title: translations().tableColumns.message,
        dataIndex: 'message',
        key: 'message',
        width: 244
    }
]
