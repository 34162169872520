import { Card, List, Avatar, Select, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { withAppStore } from '~/code/hocs/withAppStore'
import { BpmAnalyticsStageModel, OnboardingTabEnum } from '../../models'
import { Onboarding } from '../../Onboarding'
import { getTime, round } from './services/utils'
import { BpmAnalyticsHeader, GaugeChart, Carousel, SubStatuses, BpmAnalyticsHeaderV2 } from './components'
import { BpmAnalyticsProps } from './props'
import styles from './BpmAnalytics.scss'
import translations from './translations'
import { Liquid, Funnel, Gauge, Pie } from '@ant-design/plots'
import { funnelConfig, liquidGraphStyle, pieConfig } from './services/constants'

const AnalyticsView = observer((props: BpmAnalyticsProps) => {
  const { store, handbooksStore, isV2 } = props

  useEffect(() => {
    if (!store.isInitiated) {
      store.init()
    }
  }, [])

  useEffect(() => {
    if (isV2) {
      store.loadDataForV2()
    }
  }, [])

  return (
    <Onboarding activeTab={isV2 ? OnboardingTabEnum.AnalyticsV2 : OnboardingTabEnum.Analytics} store={store}>
      {isV2 && (
        <>
          <BpmAnalyticsHeaderV2 store={store} />

          <Row className={styles.styledRow} gutter={12}>
            <Col xs={24} md={12} span={12}>
              <Card loading={store.isFunnelDataLoading}>
                <div className={styles.funnelWrapper}>
                  <Funnel title={translations().uniqueAppsFunnel} data={store.funnelDataReport} {...funnelConfig} />
                </div>
              </Card>
            </Col>
            <Col xs={24} md={12} span={12}>
              <Card loading={store.isCurrentStageDataLoading}>
                <div className={styles.funnelWrapper}>
                  <Funnel title={translations().currentApps} data={store.currentStageDataReport} {...funnelConfig} />
                </div>
              </Card>
            </Col>
          </Row>

          <Row className={styles.styledRow} gutter={12}>
            <Col xs={24} md={12} span={12}>
              <Row gutter={12}>
                <Col xs={24} md={12} span={12}>
                  <Card className={styles.styledCard} loading={store.isUWDataLoading}>
                    <p className={styles.graphHeaderPie}>{translations().uwCapacity}</p>
                    <br />
                    <div className={styles.centeredDiv}>
                      <Liquid
                        className={styles.styledGraphWrapper}
                        percent={
                          store.uWDataReport?.uwCapacityAvrDaylyLiquidPercent
                            ? store.uWDataReport?.uwCapacityAvrDaylyLiquidPercent / 100
                            : 0
                        }
                        style={
                          store.uWDataReport?.uwCapacityAvrDaylyLiquidPercent > 54
                            ? { ...liquidGraphStyle, textFill: 'white' }
                            : liquidGraphStyle
                        }
                      />
                    </div>
                  </Card>
                </Col>

                <Col xs={24} md={12} span={12}>
                  <Card loading={store.isUWDataLoading} className={styles.styledCard}>
                    <p className={styles.graphHeaderPie}>{translations().uwReviews}</p>
                    <br />
                    <div className={styles.centeredDiv}>
                      <Gauge
                        className={styles.styledGraphWrapper}
                        autoFit={true}
                        legend={false}
                        {...store.gaugeConfig}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12} span={12}>
              <Card className={styles.styledCard} loading={store.isPieDataLoading}>
                <p className={styles.graphHeaderPie}>{translations().appsPerChannel}</p>
                <p className={styles.subHeader}>{translations().numOfAppsPerChannel}</p>
                <div className={styles.centeredDiv}>
                  <div className={styles.styledPieWrapper}>
                    <div className={styles.styledDiv}>
                      <Pie className={styles.styledGraphWrapper} {...pieConfig} data={store.pieDataReport} />
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {!isV2 && (
        <>
          <BpmAnalyticsHeader store={store} handbooksStore={handbooksStore} />
          <List<BpmAnalyticsStageModel>
            rowKey='name'
            className={styles.cardList}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4
            }}
            dataSource={store.data}
            renderItem={(item, index) => {
              const hasData = item.subStatuses.length > 0
              const percent = round((item.target * 100) / item.avgReviewTime)

              return (
                <List.Item key={item.name}>
                  <Card loading={store.isLoading}>
                    <Card.Meta
                      avatar={
                        <Avatar className={styles.avatar} size='small'>
                          {index + 1}
                        </Avatar>
                      }
                      title={item.description}
                    />
                    <div className={styles.clear} />
                    <Carousel
                      slides={[
                        <>
                          <div className={styles.list}>
                            <div className={styles.item}>
                              <div className={styles.label}>
                                {index > 0 ? translations().avgReviewTime : translations().avgDurationTime}
                              </div>
                              <div className={styles.value}>
                                {hasData ? <RenderTime minutes={item.avgReviewTime} /> : <Empty />}
                              </div>
                            </div>
                            <div className={styles.item}>
                              <div className={styles.label}>{translations().target}</div>
                              <div className={styles.value}>
                                {hasData ? <RenderTime minutes={item.target} /> : <Empty />}
                              </div>
                            </div>
                            <div className={styles.item}>
                              <div className={styles.label}>{translations().performance}</div>
                              <div className={styles.value}>
                                {item.avgReviewTime ? (
                                  <>
                                    {percent} <span className={styles.unit}>%</span>
                                  </>
                                ) : (
                                  <Empty />
                                )}
                              </div>
                            </div>
                          </div>
                          <GaugeChart
                            data={item.gaugeData}
                            needleValue={hasData ? item.count : null}
                            unit={translations().applications}
                          />
                        </>,
                        <SubStatuses list={item.subStatuses} />
                      ]}
                    />
                  </Card>
                </List.Item>
              )
            }}
          />
        </>
      )}
    </Onboarding>
  )
})

const Empty = () => <div className={styles.empty}>-</div>

const RenderTime: React.FC<{ minutes: number }> = ({ minutes }) => {
  const { value, unit } = getTime(minutes, true)
  return (
    <>
      {round(value)} <span className={styles.unit}>{unit}</span>
    </>
  )
}

export const BpmAnalytics = withAppStore(({ appStore: appStore }) => (
  <AnalyticsView store={appStore.onboardingBpmAnalyticsStore} handbooksStore={appStore.handbooksStore} />
))

export const BpmAnalyticsV2 = withAppStore(({ appStore: appStore }) => (
  <AnalyticsView store={appStore.onboardingBpmAnalyticsStore} handbooksStore={appStore.handbooksStore} isV2={true} />
))
