import React from 'react'
import { Form, Switch } from 'antd'
import { SettlementFormValuesType } from '../models'
import translations from '../translations'

export const childrenColumns = [
  {
    dataIndex: 'disable',
    key: 'disable',
    width: '5%',
    align: 'center' as 'center'
  },
  {
    dataIndex: 'name',
    key: 'name',
    width: 120
  }
]

export const settlementPaymentColumns = [
  {
    children: childrenColumns
  }
]

export const feesDataSettlementPayments = () => {
  return [
    {
      key: 'perCompanyUberId',
      name: translations().perCompanyUberId,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'perCompanyUberId'} valuePropName={'checked'} dependencies={['grossSettlementsByMid']}>
                <Switch disabled={true} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'perOutletMid',
      name: translations().perOutlerMid,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'perOutletMid'} valuePropName={'checked'}>
                <Switch disabled={true} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  ]
}

export const feesDataSettlementType = (
  isGrossByUberIdAvailable: boolean,
  isGrossAvailable: boolean,
  settlementFormValues: SettlementFormValuesType
) => {
  return [
    {
      key: 'dailyNetSettlements',
      name: translations().dailyNetSettlements,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'dailyNetSettlements'} valuePropName={'checked'}>
                <Switch />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'monthlyNetSettlements',
      name: translations().monthlyNetSettlements,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'monthlyNetSettlements'} valuePropName={'checked'}>
                <Switch disabled />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'grossSettlementsByMid',
      name: translations().grossSettlementsByMid(isGrossAvailable),
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'grossSettlementsByMid'} valuePropName={'checked'} dependencies={['perCompanyUberId']}>
                <Switch
                  disabled={
                    t.getFieldValue('perCompanyUberId') ||
                    settlementFormValues?.grossSettlementsByUberId ||
                    !isGrossAvailable
                  }
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'grossSettlementsByUberId',
      name: translations().grossSettlementsByUberId(isGrossByUberIdAvailable, isGrossAvailable),
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <>
                <Form.Item name={'grossSettlementsByUberId'} valuePropName={'checked'}>
                  <Switch
                    disabled={
                      !isGrossByUberIdAvailable || settlementFormValues?.grossSettlementsByMid || !isGrossAvailable
                    }
                  />
                </Form.Item>
              </>
            )
          }}
        </Form.Item>
      )
    }
  ]
}

export const settlementImmediate = () => {
  return [
    {
      key: 'settlementImmediate',
      name: translations().settlementImmediate,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'settlementImmediate'} valuePropName={'checked'} dependencies={['dailyNetSettlements']}>
                <Switch disabled={t.getFieldValue('dailyNetSettlements')} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  ]
}
