import React, { useEffect, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Drawer, Grid, Button, Input, Typography } from 'antd'
import { ToolOutlined, UnlockOutlined } from '@ant-design/icons'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { PermissionsTable, SpecificPermissions } from '~/code/pages/PartnerAccess/components'
import { PermissionCard } from '~/code/containers/Teammates/components/PermissionCard'
import { PartnerLoginAccessDrawerProps } from './props'
import {
  IPartnerAccessFormStore,
  PartnerAccessFormStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessDrawer'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const PartnerLoginAccessDrawer = observer((props: PartnerLoginAccessDrawerProps) => {
  const { open, ...rest } = props
  const [form] = Form.useForm()
  const { xs } = useBreakpoint()
  const width = xs ? window.innerWidth : 512

  const store = useInjection<IPartnerAccessFormStore>(PartnerAccessFormStoreSymbol)

  const {
    isLoading,
    partnerAccessStore,
    partnerPermissions,
    partnerSpecificPermissions,
    teammatePermissions,
    teammateSpecificPermissions,
    hasPartnerSpecificPermissions,
    updatePartnerLoginAccess
  } = store

  const { selectedTableItem, setSelectedTableItem, onPartnerLoginAccessDrawerClose } = partnerAccessStore

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<string[]>([])
  const [specificPermissions, setSpecificPermissions] = useState<string[]>([])

  useEffect(() => {
    setIsAdmin(selectedTableItem?.roleId === 'admin')
  }, [selectedTableItem])

  const handlePermissionsChange = useCallback((newPermissions: string[]) => {
    setPermissions(newPermissions)
  }, [])

  const handleSpecificPermissionsChange = useCallback((newSpecificPermissions: string[]) => {
    setSpecificPermissions(newSpecificPermissions)
  }, [])

  const onClose = () => {
    onPartnerLoginAccessDrawerClose()
  }

  const onAfterOpenChange = (visible: boolean) => {
    if (!visible) {
      form.resetFields()
      setSelectedTableItem(null)
      setPermissions([])
      setSpecificPermissions([])
    }
  }

  const onFinish = () => {
    updatePartnerLoginAccess({
      role: isAdmin ? 'admin' : 'custom',
      ...(isAdmin ? {} : { permissions: [...permissions, ...specificPermissions] })
    })
  }

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={onClose}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={isLoading}
        disabled={!hasPermissions([PermissionMap.partners.teammates.update])}
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer
      title={translations().edit}
      open={open}
      onClose={onClose}
      width={width}
      footer={renderFooter()}
      afterOpenChange={onAfterOpenChange}
      destroyOnClose
      {...rest}
    >
      <Form
        form={form}
        initialValues={selectedTableItem || {}}
        requiredMark={false}
        labelAlign='left'
        labelCol={{ span: 8 }}
        onFinish={onFinish}
      >
        <Form.Item
          name='login'
          label={translations().formFields.email}
          rules={[
            { required: true, message: translations().formErrors.emailRequired },
            { type: 'email', message: translations().formErrors.emailInvalid }
          ]}
        >
          <Input placeholder={translations().formFields.email} disabled />
        </Form.Item>

        <Form.Item
          label={translations().formFields.firstName}
          name='firstName'
          rules={[{ required: true, message: translations().formErrors.firstNameRequired }]}
        >
          <Input placeholder={translations().formFields.firstName} disabled />
        </Form.Item>

        <Form.Item
          label={translations().formFields.lastName}
          name='lastName'
          rules={[{ required: true, message: translations().formErrors.lastNameRequired }]}
        >
          <Input placeholder={translations().formFields.lastName} disabled />
        </Form.Item>

        <Typography.Title level={5}>{translations().subtitles.userAccess}</Typography.Title>

        <PermissionCard
          title={translations().permissionSelects.admin}
          content={translations().permissionSelectsText.admin}
          icon={<UnlockOutlined />}
          isActive={isAdmin === true}
          onClick={() => setIsAdmin(true)}
        />

        <PermissionCard
          title={translations().permissionSelects.restricted}
          content={translations().permissionSelectsText.restricted}
          icon={<ToolOutlined />}
          isActive={isAdmin === false}
          onClick={() => setIsAdmin(false)}
        />

        {isAdmin === false && (
          <Form.Item>
            <PermissionsTable
              permissions={partnerPermissions}
              initialPermissions={teammatePermissions}
              onPermissionsChange={handlePermissionsChange}
            />
          </Form.Item>
        )}

        {isAdmin === false && hasPartnerSpecificPermissions && (
          <Form.Item>
            <SpecificPermissions
              permissions={partnerSpecificPermissions}
              initialPermissions={teammateSpecificPermissions}
              onPermissionsChange={handleSpecificPermissionsChange}
            />
          </Form.Item>
        )}
      </Form>
    </Drawer>
  )
})
