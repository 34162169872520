import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Button, Skeleton, Table } from 'antd'
import {
  settlementPaymentColumns,
  feesDataSettlementPayments,
  feesDataSettlementType,
  settlementImmediate
} from './services/generator'
import { ChargeMethod } from './constants'
import { IChangeSettlementTypeStore } from './IChangeSettlementTypeStore'
import translations from './translations'
import styles from './ChangeSettlementType.scss'

export const ChangeSettlementTypeStoreSymbol = Symbol('ChangeSettlementTypeStore')
export const ChangeSettlementType = observer(() => {
  const store = useInjection<IChangeSettlementTypeStore>(ChangeSettlementTypeStoreSymbol)
  const [settlementForm] = Form.useForm()

  useEffect(() => {
    const initializeSettlements = async () => {
      await store.init()
      settlementForm.setFieldsValue(store.settlementFormValues)
    }
    initializeSettlements()
  }, [])

  return store.isSettlementDataLoading ? (
    <Skeleton active />
  ) : (
    <div className={styles.formContainer}>
      <Form
        form={settlementForm}
        onFinish={() => store.handleSubmitSettlement(settlementForm)}
        onValuesChange={(changedValues, _) => {
          store.handleSettlementsFormChange(changedValues, settlementForm)
        }}
      >
        <div className={styles.styledTitle}>{translations().title}</div>
        <div className={styles.tableHeader}>{translations().settlementPayment}</div>
        <div className={styles.tableSubHeader}>
          <div>{translations().settlementPaymentNote}</div>
        </div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={settlementPaymentColumns}
          dataSource={feesDataSettlementPayments()}
        />
        <div className={styles.tableHeader}>
          {translations().settlementType}
          {store.settlementTypeDisplayValue}
          {store.chargeMerchantValue === ChargeMethod.uberid && translations().byUberId}
          {store.chargeMerchantValue === ChargeMethod.mid && translations().byMid}
        </div>
        <div className={styles.tableSubHeader}>
          <div>{translations().settlementTypeNote()}</div>
        </div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={settlementPaymentColumns}
          dataSource={feesDataSettlementType(
            store.isGrossByUberIdAvailable,
            store.isGrossAvailable,
            store.settlementFormValues
          )}
        />
        <div className={styles.tableHeader}>{translations().settlementImmediate}</div>
        <div className={styles.tableSubHeader}>
          <div>{translations().settlementImmediateNote()}</div>
        </div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={settlementPaymentColumns}
          dataSource={settlementImmediate()}
        />
        <div className={styles.buttonContainer}>
          <Button
            type='primary'
            onClick={() => settlementForm.submit()}
            disabled={!store.storesDossierV2Store.isMerchantActive}
            loading={store.isChangeSettlementLoading}
          >
            {translations().submit}
          </Button>
        </div>
      </Form>
    </div>
  )
})
