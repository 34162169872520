import translations from './translations'

translations.add('en', {
  requestFail: 'Request has not been submitted!',
  requestSuccess: 'Request has been submitted!',
  errClosingMerchant: 'Error closing merchant',
  errClosingTerminal: 'Error closing terminal',
  applicationSuccessful: 'Application successfully started',
  errorUploadingDocument: 'Error uploading the document',
  errorDeletingDocument: 'Error Deleting Document',
  documentsRequired: 'Documents required',
  fetchingPOSModelsError: 'Error fetching POS models',
  fetchingMIDsError: 'Error fetching MIDs',
  errChangingBankDetals: 'Error changing bank details',
  errVerifyingBankAccount: 'Error verifying bank account',
  errBankDetailsValidation: 'Verification of bank details has failed. Bank statement document needs to be uploaded',
  errorLoadingAppData: 'Error loading application data',
  submitNewRequestSuccess: 'Submitted New Request Successfully',
  submitNewRequestError: 'Submit New Request Error',
  errLoadingBalances: 'Error occured while loading negative balance',
  errLoadingSettlementPeriod: 'Error loading settlement period',
  errorLoadingSettlementsInfo: 'Error loading Settlements info',
  dsrIdIsEmpty: 'Dossier Id is empty',
  errorEditingTariffs: 'Error while editing tariffs',
  errorNoChanges: 'There are no changes in tariffs',
  errorLoadingTipJarInfo: 'Error loading TipJar info',
  errorLoadingMerchantClosureReasons: 'Error loading merchant closure reasons',
  errorNoChangesSettlementStatus: 'There are no changes in settlement statuses',
  errorChangeRoutings: 'Error while changing routings',
  pos: 'POS',
  ecom: 'ECOM',
  topLevelSubjectIdIsEmpty: 'Top level subject id is empty',
  errorLoadingSalesForceData: 'Error while loading Sales Force Data',
  errStartingAdjustment: 'Error while staring adjustement process',
  errStartingHoldProcess: 'Error while staring Hold/Release settlements process',
  errLoadingSettlementReason: 'Error while loading Hold/Release settlements reasons',
  contractIdIsEmpty: 'Contract id is empty',
  tariffSettingsAreEmpty: 'Tariff settings are empty',
  openBanking: 'Open Banking',
  openBankingDescription: 'Charge per Transaction for using Open Banking',
  payByBank: 'Pay By Bank',
  payByBankDescription: 'Charge per Transaction for using Pay By Bank',
  errorLoadingStoreAttributes: 'Error while loading store attributes',
  attachDocuments: 'Please, attach documents',
  errorLoadingMerchantData: 'Error while loading merchant information',
  storeIdIsEmpty: 'Store id is empty',
  notDefined: 'not defined',
  errorNoChangesSettlementType: 'There are no changes in Settlement Type',
  errorNoSettlementType: 'Please, select Settlement Type'
})
