import React, { useState } from 'react'
import { Button, Checkbox, Col, List, Modal, Row, Tooltip } from 'antd'
import { CheckGreenOutlined, DeleteRedOutlined, EditOutlinedBlue } from '~/assets/icons'
import { filterEcomProducts, filterPosProducts, isCorporate } from '~/code/pages/StartProcess/services'
import translations from './translations'
import { StoresListItemProps } from './props'
import styles from './StoresListItem.scss'
import { ProductItem } from '~/code/pages/StartProcess/models'
import { isEmpty } from 'dna-common'
import { chargeTypes, terminalTypes } from '../../../ProductSelectionForm/constants'
import classNames from 'classnames'

const { confirm } = Modal

export const StoresListItem = (props: StoresListItemProps) => {
  const {
    isCompleted,
    isRemoveDisabled,
    onClick,
    onRemove,
    address,
    account,
    products,
    isEditDisabled = false,
    posModels
  } = props

  const posProducts = filterPosProducts(products)
  const ecomProducts = filterEcomProducts(products)

  const isAliPay = Boolean(posProducts.find(p => p.aliPay === true))
  const isCards = Boolean(posProducts.find(p => p.cards === true))
  const isPayByBank = Boolean(posProducts.find(p => p.payByBank === true))
  const isZashEpos = Boolean(posProducts.find(p => p.zashEpos === true))
  const isMoto = Boolean(posProducts.find(p => p.moto === true))
  const isOB = Boolean(posProducts.find(p => p.openBanking === true))

  const isPaymentLink = Boolean(ecomProducts.find(p => p.payByLink === true))
  const isVirtualTerminal = Boolean(ecomProducts.find(p => p.virtualTerminal === true))
  const isWebsitePayments = Boolean(ecomProducts.find(p => p.websitePayments === true))

  const productChargeTypes = posProducts?.map(p => chargeTypes?.find(c => c.key === p.chargeType)?.label)
  const uniqueChargeTypes = productChargeTypes?.filter((element, index) => {
    return productChargeTypes?.indexOf(element) === index
  })

  const prTerminalTypes = posProducts?.map(p => terminalTypes?.find(c => c.key === p.terminalType)?.label)
  const uniqueTerminalTypes = prTerminalTypes?.filter((element, index) => {
    return prTerminalTypes?.indexOf(element) === index
  })

  const posModel = posProducts?.map(p => posModels.find(m => m.code === p.model)?.value)

  const uniquePosModels = posModel?.filter((element, index) => {
    return posModel?.indexOf(element) === index
  })

  const deleteTitle = isCorporate(props) ? props.corporateName : `${props.name || ''}`

  const renderName = () => {
    const { name, surname } = props
    const alias = (name ? name.charAt(0) : '') + (surname ? surname.charAt(0) : '')
    return (
      <>
        <div className={styles.alias}>{alias}</div>
        <div className={styles.name}>
          {name} {surname}
        </div>
      </>
    )
  }

  const onDeleteClicked = () => {
    confirm({
      title: translations().deleteFormTitle(deleteTitle),
      content: translations().deleteFormContent,
      async onOk() {
        onRemove()
      },
      onCancel() {
        return
      }
    })
  }

  return (
    <>
      <List.Item
        actions={[
          <Tooltip>
            {isCompleted && (
              <div className={styles.checkedIcon}>
                <CheckGreenOutlined />
              </div>
            )}
          </Tooltip>,
          <Tooltip title={translations().delete}>
            {onRemove && (
              <Button
                type='text'
                icon={<DeleteRedOutlined />}
                disabled={isRemoveDisabled}
                onClick={e => {
                  e.stopPropagation()
                  onDeleteClicked()
                }}
              />
            )}
          </Tooltip>,
          <Tooltip title={translations().edit}>
            <Button type='text' icon={<EditOutlinedBlue />} disabled={isEditDisabled} onClick={onClick} />
          </Tooltip>
        ]}
      >
        <div className={styles.contentContainer}>{renderName()}</div>
      </List.Item>

      <div className={styles.container}>
        <Row gutter={24}>
          <Col span={8}>
            <Row>
              <div>
                <p className={styles.styledTitle}>{translations().outletInfo}</p>
                {!isEmpty(address) && <div>{`${address?.postalCode}, ${address?.addressLine1}`}</div>}
                {!isEmpty(account) && <div>{`${account?.sortCode}, ${account?.bankAccountNumber}`}</div>}
                <div>{uniqueChargeTypes?.join()}</div>
              </div>
            </Row>

            <Row className={classNames(styles.mb15, styles.mt15)}>
              {!isEmpty(posProducts) && (
                <div>
                  <p className={styles.styledTitle}>{translations().configure}</p>
                  <div>{uniquePosModels?.join()}</div>
                  <div>{uniqueTerminalTypes?.join()}</div>
                </div>
              )}
            </Row>
          </Col>

          <Col span={8}>
            {isEmpty(posProducts) ? (
              <p className={styles.styledInfoTxt}>{translations().noPosProds}</p>
            ) : (
              <p className={styles.styledTitle}>{translations().posProduct}</p>
            )}
            {!isEmpty(posProducts) && (
              <Row>
                {isCards && (
                  <Col className={styles.overflow} span={24}>
                    <Checkbox disabled checked>
                      {translations().cards}
                    </Checkbox>
                  </Col>
                )}
                {isAliPay && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().aliPay}
                    </Checkbox>
                  </Col>
                )}
                {isPayByBank && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().payByBank}
                    </Checkbox>
                  </Col>
                )}
                {isZashEpos && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().zashEpos}
                    </Checkbox>
                  </Col>
                )}
                {isMoto && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().moto}
                    </Checkbox>
                  </Col>
                )}
                {isOB && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().openBanking}
                    </Checkbox>
                  </Col>
                )}
              </Row>
            )}
          </Col>

          <Col span={8}>
            {isEmpty(ecomProducts) ? (
              <p className={styles.styledInfoTxt}>{translations().noEcomProds}</p>
            ) : (
              <p className={styles.styledTitle}>{translations().ecomProduct}</p>
            )}
            {!isEmpty(ecomProducts) && (
              <Row>
                {isPaymentLink && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().paymentLinks}
                    </Checkbox>
                  </Col>
                )}
                {isVirtualTerminal && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().virtualTerminal}
                    </Checkbox>
                  </Col>
                )}
                {isWebsitePayments && (
                  <Col span={24}>
                    <Checkbox disabled checked>
                      {translations().websitePayments}
                    </Checkbox>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}
