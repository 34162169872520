import { notification } from 'antd'
import { injectable } from 'inversify'
import moment from 'moment-timezone'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { ISettlementsPaymentsTableContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/ISettlementsPaymentsTableContainerStore'
import { PAGE_SIZE_100 } from '~/code/constants/Configurations'
import { DataLoadingStatus } from '~/code/models/DataLoadingStatus'
import { PaymentStatuses } from '~/code/pages/settlements/SettlementsPayments/constants/PaymentStatuses'
import {
  approvePayments,
  fetchPaymentDetails,
  sendPaymentsToApproval
} from '~/code/stores/SettlementsPaymentsStore/services/fetchers'
import {
  MAX_NUMBER_OF_SELECTABLE_ROWS,
  MAX_NUMBER_TO_APPROVE
} from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/constants/configs'
import translations from './translations'
import { OrderTypeEnum } from '~/code/stores/SettlementsPaymentsStore/models'
import { generateApprovePaymentsRequest } from '~/code/stores/SettlementsPaymentsStore/services/utils'

// @ts-ignore
@injectable()
export class SettlementsPaymentsTableContainerStore implements ISettlementsPaymentsTableContainerStore {
  constructor() {
    this.configureMobXProps()
  }

  fetchData

  data = []

  isSelectedAll: boolean = false

  dataLoadingStatus: DataLoadingStatus = 'idle'
  currentPage: number = 1

  pageSize: number = PAGE_SIZE_100
  totalCount: number = 0
  totalAmount: number = 0
  orderBy: string = ''
  orderType: OrderTypeEnum = null

  selectedStatus: PaymentStatuses = PaymentStatuses.all
  selectedRows = []

  paymentHistory = null
  paymentHistoryLoadingStatus: DataLoadingStatus = 'idle'

  get selectedPayments() {
    return this.selectedRows.map(row => {
      return this.data[row]
    })
  }

  get isPaginationDisabled() {
    if (this.dataLoadingStatus !== 'finished' && this.dataLoadingStatus !== 'failed') {
      return true
    }
    return false
  }

  loadData = async ({ pageNumber } = { pageNumber: this.currentPage }) => {
    runInAction(() => {
      this.dataLoadingStatus = 'loading'
      this.selectedRows = []
    })

    const { data, total, error } = await this.fetchData({
      currentPage: pageNumber,
      pageSize: this.pageSize,
      orderType: this.orderType ? this.orderType : undefined,
      orderBy: this.orderBy.length ? this.orderBy : undefined
    })

    runInAction(() => {
      this.data = data.map((item, index) => ({ ...item, index })) // , status:  (Math.floor(Math.random() * 10) % 2 === 1) ? PaymentStatuses.new : PaymentStatuses.waitingApproval }))
      this.totalCount = total.totalCount
      this.totalAmount = total.totalAmount
      this.currentPage = pageNumber
      this.isSelectedAll = false

      if (error) {
        this.dataLoadingStatus = 'failed'
      } else {
        this.dataLoadingStatus = 'finished'
      }
    })
  }

  onPageChange = (pageNumber: number, pageSize: number) => {
    this.currentPage = pageNumber
    this.pageSize = pageSize
    this.loadData({ pageNumber })
  }

  setSelectedRows = (record, selected, selectedRows, nativeEvent) => {
    if (selectedRows.some(row => row.amountToMerchant <= 0))
      notification.error({ message: translations().negativeOrZeroApprove })

    this.selectedRows = selectedRows
      .filter(row => row.amountToMerchant > 0)
      .map(row => row.key)
      .slice(0, MAX_NUMBER_OF_SELECTABLE_ROWS)
  }

  selectAllRows = (selected, selectedRows, changeRows) => {
    this.isSelectedAll = !this.isSelectedAll

    if (this.isSelectedAll) {
      this.selectedRows = selectedRows
        .filter(row => row.amountToMerchant > 0)
        .map(row => row.key)
        .slice(0, MAX_NUMBER_OF_SELECTABLE_ROWS)
    } else this.selectedRows = []
  }

  onReloadButtonClick = () => {
    this.loadData()
  }

  onSendToApprovalSelectedButtonClick = async () => {
    const newPayments = this.selectedPayments
      .filter(payment => payment.status === PaymentStatuses.new)
      .map(payment => payment.documentId)

    runInAction(() => {
      this.dataLoadingStatus = 'loading'
    })

    const { result, error } = await sendPaymentsToApproval(newPayments)

    if (error) {
      runInAction(() => {
        this.dataLoadingStatus = 'failed'
      })

      notification.error({ message: translations().failedToSendPaymentsForApproval })
      return
    } else if (result?.length > 0) {
      notification.error({ message: translations().couldNotSendToApprovalTheFollowing(result), duration: 0 })
    }

    this.loadData({ pageNumber: 1 })
  }

  onSendToReApprovalSelectedButtonClick = async () => {
    const newPayments = this.selectedPayments
      .filter(({status}) => status === PaymentStatuses.failed || status === PaymentStatuses.rejected || status === PaymentStatuses.bounced)
      .map(payment => payment.documentId)

    runInAction(() => {
      this.dataLoadingStatus = 'loading'
    })

    const { result, error } = await sendPaymentsToApproval(newPayments)

    if (error) {
      runInAction(() => {
        this.dataLoadingStatus = 'failed'
      })

      notification.error({ message: translations().failedToSendPaymentsForApproval })
      return
    } else if (result?.length > 0) {
      notification.error({ message: translations().couldNotSendToApprovalTheFollowing(result), duration: 0 })
    }

    this.loadData({ pageNumber: 1 })
  }

  onApproveSelectedButtonClick = async () => {
    const paymentsToApprove = this.selectedPayments
      .filter(payment => payment.status === PaymentStatuses.waitingApproval)
      .slice(0, MAX_NUMBER_TO_APPROVE)
    runInAction(() => {
      this.dataLoadingStatus = 'loading'
    })

    const { result, error } = await approvePayments(generateApprovePaymentsRequest(paymentsToApprove))
    if (error) {
      runInAction(() => {
        this.dataLoadingStatus = 'failed'
      })

      notification.error({ message: translations().failedToApproveThePayments })
      return
    }
    this.loadData({ pageNumber: 1 })
  }

  onRowClick = async payment => {
    runInAction(() => {
      this.paymentHistoryLoadingStatus = 'loading'
    })

    const { result, error } = await fetchPaymentDetails(payment?.documentId)

    runInAction(() => {
      this.paymentHistory = result?.reverse().map(item => ({
        ...item,
        date: moment(item.date).format('DD.MM.YYYY HH:mm')
      }))

      if (error) {
        this.paymentHistoryLoadingStatus = 'failed'
      } else {
        this.paymentHistoryLoadingStatus = 'finished'
      }
    })
  }

  onTableChange(pagination, filters, sorter) {
    if (sorter.order && sorter.field.length) {
      this.orderBy = sorter.field
      this.orderType = OrderTypeEnum[sorter.order]
    } else {
      this.orderBy = ''
      this.orderType = null
    }

    this.loadData({ pageNumber: this.currentPage })
  }

  clear = () => {
    this.data = []
    this.dataLoadingStatus = 'idle'
    this.currentPage = 1
    this.pageSize = PAGE_SIZE_100
    this.totalCount = 0
    this.totalAmount = 0
    this.selectedRows = []
    this.paymentHistory = []
    this.paymentHistoryLoadingStatus = 'idle'
  }

  private configureMobXProps = () => {
    makeObservable(this, {
      // observables
      selectedStatus: observable,
      selectedRows: observable,
      dataLoadingStatus: observable,
      data: observable,
      currentPage: observable,
      pageSize: observable,
      totalCount: observable,
      totalAmount: observable,
      paymentHistory: observable,
      paymentHistoryLoadingStatus: observable,
      isSelectedAll: observable,
      orderBy: observable,
      orderType: observable,

      // computed
      selectedPayments: computed,
      isPaginationDisabled: computed,

      // actions
      loadData: action,
      onPageChange: action,
      setSelectedRows: action,
      selectAllRows: action,
      onSendToApprovalSelectedButtonClick: action,
      onApproveSelectedButtonClick: action,
      onRowClick: action,
      onTableChange: action.bound,
      clear: action
    })
  }
}
