export const DATE_PATTERN = /(0[1-9]|1[0-9]|2[0-9]|3[0-1])-(01|02|03|04|05|06|07|08|09|10|11|12)-[12][0-9]{3}/
export const HOUSE_NAME_NUMBER_TYPING_PATTERN = /^([a-zA-Z]|\d)+(\s|\/|-)?(([a-zA-Z]|\d)+(\s|\/|-)?)*$/
export const CITY_TYPING_PATTERN = /^([a-zA-Z])+(\s|-)?(([a-zA-Z])+(\s|-)?)*$/
export const PHONE_NUMBER_PATTERN = /^(\+44(\d){10}|0(\d){10})$/
export const PHONE_NUMBER_TYPING_PATTERN = /^(\+(4){0,2}|\+44(\d){0,10}|07?|07(\d){0,9})$/
export const VALUE_IN_POUNDS_TYPING_PATTERN = /^£ (\d)*$/
export const VALUE_IS_NOT_NUMBER_OR_COMMA_OR_DOT = /[^\d,\.]/g
export const URL_PATTERN = /^(https?:\/\/)?(www\.)?(?!www\.)(?!.*\.$)(?!.*?\.\.)(([a-z0-9]+[-]*[a-z0-9]+)+|[a-zA-Z0-9]+)\.[^A-Z\s]{2,}$/
export const EMAIL_VERIFICATION_CODE_TYPING_PATTERN = /^(\d){0,6}$/
export const PASSWORD_REQUIRED_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@_!#$%^&*()<>?/\|\-\.,=+}{~:;'"`\[\]\\±§])[A-Za-z\d@_!#$%^&*()<>?/\|\-\.,=+}{~:;'"`\[\]\\±§]{8,}$/
export const TIDS_PATTERN =  /tid:\s*(\d+);/g
export const URL_PATTERN_2 = /^(https?:\/\/)(www\.)?(?!www\.)(?!.*\.$)(?!.*?\.\.)(([a-z0-9]+[-]*[a-z0-9]+)+|[a-zA-Z0-9]+)\.[^A-Z\s]{2,}$/

