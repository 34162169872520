import { getWithAuth, postWithAuth } from '~/code/services'
import { OrderTypeEnum } from '~/code/stores/SettlementsPaymentsStore/models'

export interface SettlementsPaymentsParams {
    from: string
    to: string
    page: number
    size: number
    status?: string
    merchantId?: string
    acquisitionChannel?: string
    orderType?: OrderTypeEnum
    orderBy?: string
}

export const fetchSettlementsPayments = (data: SettlementsPaymentsParams) =>
    getWithAuth<any>(`/api/v1/way4/payments`, data)

export const sendPaymentsToApproval = (data: string[]) =>
    postWithAuth<any>('/api/v1/clearbank/payments/approvals', data)

export const approvePayments = (data) =>
  postWithAuth<any>('/api/v1/clearbank/payments', data)

export const fetchPaymentDetails = (paymentId) =>
    getWithAuth<any>(`/api/v1/way4/payments/${paymentId}/history`)

