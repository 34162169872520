import { inject, injectable } from 'inversify'
import { action, autorun, computed, makeObservable, observable } from 'mobx'
import { message } from 'antd'
import { RangePickerStoreInjectable } from '~/code/stores/RangePickerStoreInjectable'
import { PeriodType } from '~/code/models'
import { getFullData } from '~/code/stores/MotoVtRequestsStore/services/getters'
import commonTranslations from '~/code/translations/translations'
import { IBPMProcessesStore, SubmittedApplicationReport } from '~/code/pages/BPMProcesses/Requests/models'
import { getProcessStatuses, getSubmittedApplications } from '~/code/pages/BPMProcesses/Requests/services'
import {
  ChangeOfSettlementKindMerchantSelectStoreSymbol,
  ChangeOfSettlementKindRangePickerStoreSymbol
} from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementKind'
import { MerchantSelectStore } from '../MerchantsManagementStore/MerchantSelectStore'

@injectable()
export class ChangeOfSettlementKindStore implements IBPMProcessesStore {
  dateStore: RangePickerStoreInjectable
  merchantSelectStore: MerchantSelectStore
  isProcessesLoading: boolean
  isStatusesLoading: boolean
  total: number
  processes: SubmittedApplicationReport[]
  statuses: { label: string; value: string }[]
  status: string | null = null
  currentPage: number
  pageSize: number

  constructor(
    @inject(ChangeOfSettlementKindRangePickerStoreSymbol) rangePickerStoreInjectable: RangePickerStoreInjectable,
    @inject(ChangeOfSettlementKindMerchantSelectStoreSymbol) merchantSelectStore: MerchantSelectStore
  ) {
    this.dateStore = rangePickerStoreInjectable
    this.merchantSelectStore = merchantSelectStore
    this.isProcessesLoading = false
    this.isStatusesLoading = false
    this.processes = []
    this.statuses = []
    this.currentPage = 1
    this.pageSize = 10
    this.total = 0

    makeObservable(this, {
      isProcessesLoading: observable,
      isStatusesLoading: observable,
      processes: observable,
      statuses: observable,
      status: observable,
      currentPage: observable,
      pageSize: observable,
      total: observable,

      merchant: computed,

      init: action.bound,
      loadProcesses: action.bound,
      loadStatuses: action.bound,
      setStatus: action.bound
    })

    autorun(() => {
      if (this.dateStore.startDate && this.dateStore.endDate && this.merchant) {
        this.loadProcesses({ page: 1 })
      }
    })
  }

  get merchant() {
    return this.merchantSelectStore.selectedMerchant?.companyName
  }

  async init() {
    if (this.dateStore.period === null) {
      this.dateStore.setPeriod(PeriodType.Day)
    } else {
      this.loadProcesses({ page: 1 })
    }
    this.loadStatuses()
  }

  setStatus(status: string | null) {
    this.status = status
    this.loadProcesses({ page: 1 })
  }

  async loadProcesses({ page, pageSize }: { page?: number; pageSize?: number }) {
    if (page) {
      this.currentPage = page
    }
    if (pageSize) {
      this.pageSize = pageSize
    } else this.pageSize = 10

    this.isProcessesLoading = true

    const { status, result, error } = await getSubmittedApplications({
      from: this.dateStore.startDate.clone().utc().format(),
      to: this.dateStore.endDate.clone().utc().format(),
      status: this.status || undefined,
      companyName: this.merchant === 'All' ? undefined : this.merchant,
      processName: 'settlements',
      page: this.currentPage,
      size: this.pageSize
    })
    if (status === 200) {
      this.isProcessesLoading = false
      this.processes = result.report
      this.total = result.totalCount
      return
    }
    this.isProcessesLoading = false
    message.error(error?.message || commonTranslations().defaultErrorMessage)
  }

  async loadStatuses() {
    this.isStatusesLoading = true
    const { status, result, error } = await getProcessStatuses('settlements')
    if (status === 200) {
      this.isStatusesLoading = false
      this.statuses = getFullData(result.map(item => ({ label: item.description, value: item.name })))
      return
    }
    this.isStatusesLoading = false
    message.error(error?.message || commonTranslations().defaultErrorMessage)
  }
}
