import React from 'react'
import { Timeline } from 'antd'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import translations from '../../../translations/translations'
import { PaymentStatuses } from '~/code/pages/settlements/SettlementsPayments/constants/PaymentStatuses'
import { ClockCircleOutlined } from '@ant-design/icons'
import styles from '../SettlementPaymentsTableContainer.scss'

export const renderTimeline = paymentHistory => {
  const lastItem = paymentHistory[paymentHistory.length - 1]
  return (
    <Timeline mode={'left'} className={styles.timeline}>
      {paymentHistory?.map((item, index) => {
        const message = generateMessage(item, lastItem?.status)
        return (
          <Timeline.Item
            label={item.date}
            color={generateTimelineItemDotColor(paymentHistory, index, item.status)}
            dot={index === paymentHistory.length - 1 ? generateTimelineItemDot(item.status) : undefined}
          >
            {message}
          </Timeline.Item>
        )
      })}
    </Timeline>
  )
}

const generateMessage = (paymentHistoryItem, lastPaymentStatus) => {
  let message = (
    <>
      <p>
        <b>{translations().paymentStatuses[paymentHistoryItem.status]}</b>
      </p>
      {paymentHistoryItem.message ? <p>{paymentHistoryItem.message}</p> : ''}
    </>
  )

  let statusText = translations().paymentStatuses[paymentHistoryItem.status]
  if (paymentHistoryItem.status === PaymentStatuses.waitingApproval) {
    if (
      lastPaymentStatus === PaymentStatuses.processing ||
      lastPaymentStatus === PaymentStatuses.rejected ||
      lastPaymentStatus === PaymentStatuses.failed ||
      lastPaymentStatus === PaymentStatuses.settled
    ) {
      statusText = translations().paymentHistory.sentForApproval
    }
    message = (
      <>
        <p>
          <b>{statusText}</b>
        </p>
        <p>{translations().paymentHistory.sentForApprovalBy(paymentHistoryItem.email)}</p>
      </>
    )
  } else if (paymentHistoryItem.status === PaymentStatuses.processing) {
    if (
      lastPaymentStatus === PaymentStatuses.rejected ||
      lastPaymentStatus === PaymentStatuses.failed ||
      lastPaymentStatus === PaymentStatuses.settled
    ) {
      statusText = translations().paymentHistory.paymentSentToClearBank
    }
    message = (
      <>
        <p>
          <b>{statusText}</b>
        </p>
        <p>{translations().paymentHistory.initiatedBy(paymentHistoryItem.email)}</p>
      </>
    )
  }

  return message
}

const generateTimelineItemDot = (lastPaymentStatus: PaymentStatuses) => {
  switch (lastPaymentStatus) {
    case PaymentStatuses.waitingApproval:
    case PaymentStatuses.processing:
      return <ClockCircleOutlined style={{ color: 'orange', fontSize: '13px' }} />
    default:
      return undefined
  }
}

const generateTimelineItemDotColor = (paymentHistory, currentIndex, paymentStatus) => {
  if (paymentHistory.length === 1 && paymentStatus === PaymentStatuses.new) {
    return 'blue'
  }

  if (currentIndex < paymentHistory.length - 1) {
    if (paymentStatus === PaymentStatuses.bounced ||
      paymentStatus === PaymentStatuses.rejected ||
      paymentStatus === PaymentStatuses.failed) return 'red'
    return 'green'
  } else {
    switch (paymentStatus) {
      case PaymentStatuses.waitingApproval:
      case PaymentStatuses.processing:
        return 'orange'
      case PaymentStatuses.failed:
      case PaymentStatuses.rejected:
      case PaymentStatuses.bounced:
        return 'red'
      case PaymentStatuses.settled:
        return 'green'
      default:
        return 'blue'
    }
  }
}

export const generateBadgeStatus = (paymentStatus: PaymentStatuses): PresetStatusColorType => {
  switch (paymentStatus) {
    case PaymentStatuses.waitingApproval:
    case PaymentStatuses.processing:
      return 'warning'
    case PaymentStatuses.failed:
    case PaymentStatuses.rejected:
    case PaymentStatuses.bounced:
      return 'error'
    case PaymentStatuses.settled:
      return 'success'
    default:
      return 'processing'
  }
}
