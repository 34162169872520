import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    status: string
    viewDetails: string
    paymentStatuses: {
        all: string
        new: string
        waiting_approval: string
        processing: string
        failed: string
        settled: string
        rejected: string
        bounced: string
    }
    tableHeader: {
        totalSumTitle: string
        totalSumShortTitle: string
    },
    tableActionButtons: {
        sendForApproval: string
        approve: string
        sendForApprovalSelected: (count: number) => string
        approveSelected: (count: number) => string
        reApproveSelected: (count: number) => string
        pleaseConfirmYourAction: string
        areYouSureToSendForApproval: string
        areYouSureToApprove: string
        areYouSureToReapprove: string
    }
    tableColumns: {
        postingDate: string
        merchant: string
        amountToMerchant: string
        currency: string
        status: string
        message: string
        sortCode: string
        accountNumber: string
        endToEndId: string
        documentId: string
        reference: string
        payOutReference: string
        bankPaymentId: string
        action: string
    }
    paymentHistory: {
        paymentStatusHistory: string
        sentForApprovalBy: (email) => string
        initiatedBy: (email) => string
        paymentHistoryNotAvailable: string
        sentForApproval: string
        paymentSentToClearBank: string
    }
}>()

